/**
 * Represents a controller for a data table.
 * This controller is responsible for managing interactions with a data table,
 * including fetching data, rendering the table, and handling search functionality.
 *
 * @class
 * @extends Controller
 * @example
 * <div data-controller="lineItemsTable">
 *   <div>
 *     <!-- THE REST OF YOUR CODE-->
 *     <div class='d-flex mt-3'>
 *       <div class='flex-grow-1'>
 *         <!--INPUT ELEMENT define data-action="input->lineItemsTable#searchTable-->
 *         <div class='input-group w-25'>
 *           <input type='text' class='form-control' placeholder='Search...' aria-label='Search'
 *             aria-describedby='sales-order-button'
 *             data-lineItemsTable-target="searchInput" data-action="input->lineItemsTable#searchTable"
 *           >
 *           <div class='input-group-append'>
 *             <button type='button' class='btn btn-success' id='sales-order-button'><i class='fa fa-search'></i>
 *             </button>
 *           </div>
 *         </div>
 *       </div>
 *
 *     </div>
 *   </div>
 *   <!--INCLUDE THE TABLE COMPONENT width its PROPS-->
 *   {% include "line-item-table.html" with table_context="collection_lines" perms="CAN_MAKE_SALES" object_id=object.pk query_param="?collection_id" %}
 *   table_context="collection_lines" maps to the key in "lineItemsTableProps"
 *   object_id=object.pk ---> its id of object with line items
 *   query_param="?collection_id" ----> query parameter to be used
 * </div>
 */
import { Controller } from '@hotwired/stimulus';
import dataTable from '../components/datatable';
import { lineItemsTableProps } from '../constants/tableConstants';

export default class extends Controller {
  static targets = ['context', 'table', 'searchInput'];
  columns = null;
  ajaxURL = null;
  ajaxParams = { created__lte: null, created__gte: null, search: null };

  connect() {
    this.unpackLineItemTableProps();
    this.drawTable();
  }

  /**
   * Updates the elements of the data table controller.
   */
  unpackLineItemTableProps() {
    let tableContext = this.contextTarget.dataset.context;
    let pageContext = lineItemsTableProps[tableContext];
    this.ajaxURL = this.getUrl();
    this.columns = pageContext.columns;
    this.exportURL = pageContext.exportURL;
  }

  /**
   * Draws the table using the specified parameters.
   */
  drawTable() {
    dataTable(this.tableTarget, this.ajaxURL, this.ajaxParams, this.columns);
  }

  /**
   * Performs a search on the table based on the value entered in the search input field.
   */
  searchTable() {
    let searchValue = this.searchInputTarget.value.trim();
    this.ajaxParams.search = searchValue;
    this.drawTable();
  }

  getUrl() {
    /**
     * Define the URL to be used to fetch line items of a given object
     * Takes the object_id and the query parameter
     * Example:
     *   object_id=127ahjaklke89292
     *   queryParam="?collection_id"
     *   the url will be "127.0.0.1:8000/api/collection-line-items/??collection_id=127ahjaklke89292"
     *
     */
    let tableContext = this.contextTarget.dataset.context;
    let pageContext = lineItemsTableProps[tableContext];
    let objectId = this.contextTarget.dataset.objectid;
    let queryParam = this.contextTarget.dataset.queryparam;
    let url =
      objectId && queryParam
        ? `${pageContext.ajaxURL}${queryParam}=${objectId}`
        : pageContext.ajaxURL;
    return url;
  }
}
