import { Controller } from '@hotwired/stimulus';
const objectIds = {
  csrf_token: 'input[name=csrfmiddlewaretoken]',
  senderId: '#senderId',
  paymentTermInput: '#paymentTermInput',
  collectionIdELementId: '#collectionIdElement',
};
import $ from 'jquery';
export default class togglePaymentTerm extends Controller {
  static targets = [
    'dropdown',
    'showDropdown',
    'feedbackMessage',
    'feedbackContainer',
    'feedbackAlert',
    'feedbackMessage',
    'collectionIdentifier',
  ];
  PAYMENT_TERM_URL = '/partners/api/partner/payment_term';
  collectionId;
  paymentTermInput;
  csrf_token;
  TRANSITION_COLLECTION_URL = '/api/collections/transition/multiple/';

  async connect() {
    let { csrf_token, senderIdElement, paymentTermInput, collectionIdELement } =
      this.prepareUIElements(objectIds);
    this.collectionId = collectionIdELement.innerText.trim();
    this.paymentTermInput = paymentTermInput;
    this.csrf_token = csrf_token;
    let paymentTermInputId = paymentTermInput.id;
    let senderId = senderIdElement.innerText.trim();
    let partnerData = {
      partner: senderId,
      order_price: 0,
    };
    let paymentTermData = await this.fetchPartnerPaymentTerms(
      partnerData,
      this.PAYMENT_TERM_URL,
      csrf_token,
    );
    this.populatePaymentTerm(paymentTermData, paymentTermInputId);
  }

  showPaymentTermDropdown() {
    this.dropdownTarget.classList.toggle('d-none');
    this.dropdownTarget.classList.toggle('d-flex');
    this.dropdownTarget.classList.add('slideIn');
    this.showDropdownTarget.classList.add('d-none');
  }
  hidePaymentTermDropdown() {
    this.dropdownTarget.classList.add('slideOut');
    this.dropdownTarget.classList.remove('d-flex');
    this.dropdownTarget.classList.add('d-none');
    this.showDropdownTarget.classList.remove('d-none');
  }

  prepareUIElements(objectIds) {
    return {
      csrf_token: document.querySelector(objectIds['csrf_token']),
      senderIdElement: document.querySelector(objectIds['senderId']),
      paymentTermInput: document.querySelector(objectIds['paymentTermInput']),
      collectionIdELement: document.querySelector(
        objectIds['collectionIdELementId'],
      ),
    };
  }

  populatePaymentTerm = (data, paymentTermElementId) => {
    let selectedOption = document.createElement('option');
    selectedOption.value = data.payment_term;
    selectedOption.text = data.name;
    let paymentTermElement = document.querySelector(`#${paymentTermElementId}`);
    paymentTermElement.appendChild(selectedOption);
    paymentTermElement.value = selectedOption.value;

    let organisationPaymentTerms = data.org_payment_terms;
    if (organisationPaymentTerms.length >= 1) {
      organisationPaymentTerms.forEach(paymentTerm => {
        let option = document.createElement('option');
        option.value = paymentTerm.pk;
        option.text = paymentTerm.name;
        paymentTermElement.appendChild(option);
      });
    }
    $(paymentTermElement).select2({
      theme: 'bootstrap-5',
    });
  };

  async makeApiRequest(partnerData, url, csrf_token, method) {
    let data = JSON.stringify(partnerData);
    const response = await fetch(url, {
      method,
      mode: 'cors',
      headers: {
        'X-CSRFToken': csrf_token.value,
        'Content-Type': 'application/json',
      },
      body: data,
    });
    return response;
  }

  async fetchPartnerPaymentTerms(partnerData, url, csrf_token) {
    const response = await this.makeApiRequest(
      partnerData,
      url,
      csrf_token,
      'POST',
    );
    const responseData = await response.json();
    return responseData;
  }

  closeAlert() {
    let feedbackContainer = this.feedbackContainerTarget;
    feedbackContainer.setAttribute('hidden', true);
  }

  validatePaymentTerm(inputValue, collectionId) {
    let feedbackContainer = this.feedbackContainerTarget;
    let feedbackAlert = this.feedbackAlertTarget;
    let feedbackMessage = this.feedbackMessageTarget;
    if (inputValue === 'choose') {
      feedbackAlert.classList.add('show', 'alert-danger', 'slideIn');
      feedbackContainer.removeAttribute('hidden');
      feedbackMessage.innerText = `Please select a valid payment term in order to confirm collection ${collectionId}`;
      feedbackMessage.removeAttribute('hidden');
      return false;
    } else {
      feedbackAlert.classList.remove('show', 'alert-danger', 'slideIn');
      feedbackContainer.setAttribute('hidden', true);
      feedbackMessage.setAttribute('hidden', true);
      return true;
    }
  }

  async confirmCollection(event) {
    let confirmCollectionButton = event.target;
    let transitionStatus = confirmCollectionButton.getAttribute(
      'data-transition-status',
    );
    let transitionURL = `${this.TRANSITION_COLLECTION_URL}${transitionStatus}/`;
    let payment_term_id = this.paymentTermInput.value;
    let collectionName = this.collectionIdentifierTarget.innerText;
    let isPaymentTermsValid = this.validatePaymentTerm(
      payment_term_id,
      collectionName,
    );

    let collectionId = this.collectionId;
    if (isPaymentTermsValid) {
      let items = [collectionId];
      let httpMethod = 'POST';
      let postData = {
        items,
        payment_term_id,
      };

      let dropdownContainer = this.dropdownTarget;

      const response = await this.makeApiRequest(
        postData,
        transitionURL,
        this.csrf_token,
        httpMethod,
      );
      let feedbackContainer = this.feedbackContainerTarget;
      let feedbackAlert = this.feedbackAlertTarget;
      let feedbackMessage = this.feedbackMessageTarget;
      let messageElements = {
        collectionId: this.collectionIdentifierTarget.innerText,
      };
      let feedbackElements = {
        feedbackContainer,
        feedbackAlert,
        feedbackMessage,
        messageElements,
      };
      handleTransitionResponse(
        response,
        dropdownContainer,
        feedbackElements,
        getErrMessage,
      );
    }
  }
}

export function handleTransitionResponse(
  response,
  dropdownContainer,
  feedbackElements,
  getErrMessage,
) {
  const statusCode = response.status;
  if (response.ok) {
    handleSuccessfulResponse(dropdownContainer, feedbackElements);
  } else {
    handleErrorResponse(statusCode, feedbackElements, getErrMessage);
  }
}

export function handleSuccessfulResponse(dropdownContainer, feedbackElements) {
  feedbackElements.feedbackAlert.classList.add(
    'show',
    'alert-success',
    'slideIn',
  );
  feedbackElements.feedbackContainer.removeAttribute('hidden');
  feedbackElements.feedbackMessage.innerText = `Request to confirm collection ${feedbackElements.messageElements.collectionId} was received successfully.Once marked as confirmed, you can proceed to complete the collection to initiate the generation of deliveries which will be grouped into shipments.`;
  feedbackElements.feedbackMessage.removeAttribute('hidden');

  feedbackElements.feedbackMessage.classList.add('text-success');
  feedbackElements.feedbackAlert.removeAttribute('hidden');

  dropdownContainer.classList.remove('d-flex');
  dropdownContainer.classList.add('d-none');
  reloadPage();
}

export function reloadPage() {
  setTimeout(() => {
    // Reload the page
    /* eslint-disable no-self-assign */
    window.location.href = window.location.href;
  }, 5000);
}

export function getErrMessage(statusCode) {
  const statusMessages = {
    400: 'Bad request. Please check your input.',
    401: 'Unauthorized. Please log in to access this resource.',
    403: "Forbidden. You don't have permission to access this resource.",
    404: 'sorry! The requested resource was not found on the server,please contact support for assistance.',
    500: 'An internal server error occurred while processing the request. Please try again later.',
  };

  if (statusCode in statusMessages) {
    return statusMessages[statusCode];
  } else {
    return 'An unknown error occurred.';
  }
}

export function handleErrorResponse(
  statusCode,
  feedbackElements,
  getErrMessage,
) {
  let errorMessage = getErrMessage(statusCode);
  feedbackElements.feedbackAlert.classList.add(
    'show',
    'alert-danger',
    'slideIn',
  );
  feedbackElements.feedbackContainer.removeAttribute('hidden');
  feedbackElements.feedbackMessage.innerText = errorMessage;
  feedbackElements.feedbackMessage.removeAttribute('hidden');

  feedbackElements.feedbackMessage.classList.remove('text-success');
  feedbackElements.feedbackMessage.classList.add('text-danger');
  feedbackElements.feedbackAlert.removeAttribute('hidden');
}
