/* global Set */
import { Controller } from '@hotwired/stimulus';
import {
  addClass,
  addToSet,
  clearInputValue,
  clearSet,
  fetchGetRequest,
  fetchPostRequest,
  generatePhoneDisplay,
  getCountDisplay,
  hasClass,
  removeClass,
  removeFromSet,
  setInnerHTML,
  setTextContent,
} from '../utils/bulkSMSUtils';

export default class extends Controller {
  static targets = [
    'phone',
    'message',
    'phonesCount',
    'phonesDiv',
    'feedback',
    'phoneFeedback',
    'smsButton',
    'smsDisplay',
    'balanceButton',
    'senderID',
    'apiKey',
    'userName',
  ];

  phoneSet = new Set();

  /**
   * Add phone to a set and display
   * @returns {undefined}
   */
  addPhone() {
    if (!this.phone || !hasClass(this.phoneTarget, 'is-valid')) {
      setTextContent(
        this.phoneFeedback,
        'Ensure you provide a valid phone number!',
      );
      return;
    }
    setTextContent(this.phoneFeedback, '');
    this.phoneSet = addToSet(this.phoneSet, this.phone);
    this.syncDisplay();
  }

  /**
   * Remove initially selected phone.
   * @param {Event} event
   */
  removePhone(event) {
    const phone = event.target.dataset.phone;
    this.phoneSet = removeFromSet(this.phoneSet, phone);
    this.syncDisplay();
  }

  /**
   * Handle send SMS request
   * @param {Event} event
   */
  async sendSMS(event) {
    event.preventDefault();
    if (this.phoneSet.size === 0 || this.message === '') {
      setTextContent(
        this.phoneFeedback,
        'Ensure you have provided one or more valid phone numbers!',
      );
      return;
    }

    addClass(this.smsButton, 'disabled');
    setTextContent(this.phoneFeedback, '');

    const data = new FormData();
    data.append('recipients', JSON.stringify(Array.from(this.phoneSet)));
    data.append('message', this.message);

    const response = await fetchPostRequest('', data, this.csrfToken);
    const responseText = await response.text();

    if (response.status == 200) {
      this.displaySuccess(responseText);
      clearInputValue(this.messageTarget);
      this.phoneSet = clearSet(this.phoneSet);
      this.syncDisplay();
    } else {
      this.displayError(responseText);
    }

    removeClass(this.smsButton, 'disabled');
  }

  /**
   * Refresh phones display on the DOM, updates counts and cleans inputs.
   */
  syncDisplay() {
    setInnerHTML(this.phonesDiv, generatePhoneDisplay(this.phoneSet));
    clearInputValue(this.phoneTarget);
    setTextContent(this.phonesCount, getCountDisplay(this.phoneSet.size));
  }

  /**
   * Check SMS balance.
   */
  async smsBalance() {
    addClass(this.balanceButton, 'disabled');

    const response = await fetchGetRequest(
      '/sms/bulksms/balance/',
      this.csrfToken,
    );
    const responseText = await response.text();

    if (response.status == 200) {
      setTextContent(this.smsDisplay, responseText);
    } else {
      setTextContent(this.smsDisplay, 'Try again');
    }

    removeClass(this.balanceButton, 'disabled');
  }

  async testSMSConfiguration(event) {
    const button = event.target;
    if (this.senderID === '' || this.apiKey === '' || this.userName === '') {
      this.displayError(
        'Ensure all fields are correctly filled before testing!',
      );
      return;
    }

    addClass(this.feedback.parentElement, 'd-none');

    if (!this.phone || !hasClass(this.phoneTarget, 'is-valid')) {
      setTextContent(
        this.phoneFeedback,
        'Ensure you provide a valid phone number!',
      );
      return;
    }

    setTextContent(this.phoneFeedback, '');
    addClass(button, 'disabled');

    const data = new FormData();

    data.append('sender_id', this.senderID);
    data.append('api_key', this.apiKey);
    data.append('username', this.userName);
    data.append('phone_number', this.phone);

    const response = await fetchPostRequest(
      '/sms/test-sending-sms/',
      data,
      this.csrfToken,
    );
    const responseJson = await response.json();

    if (response.status == 200) {
      this.displaySuccess(responseJson.message);
      clearInputValue(this.phoneTarget);
      removeClass(this.smsButton, 'disabled');
    } else {
      this.displayError(responseJson.error);
    }

    removeClass(button, 'disabled');
  }

  displaySuccess(feedback) {
    setTextContent(this.feedback, feedback);
    removeClass(this.feedback.parentElement, 'd-none');
    removeClass(this.feedback.parentElement, 'alert-danger');
    addClass(this.feedback.parentElement, 'alert-success');
  }

  displayError(feedback) {
    setTextContent(this.feedback, feedback);
    removeClass(this.feedback.parentElement, 'd-none');
    removeClass(this.feedback.parentElement, 'alert-success');
    addClass(this.feedback.parentElement, 'alert-danger');
  }

  /**
   * Get csrf token value from element
   * @returns {String} A string representing the phone value
   */
  get csrfToken() {
    return document.querySelector('input[name="csrfmiddlewaretoken"]').value;
  }

  /**
   * Get phone value from target
   * @returns {String} A string representing the phone value
   */
  get phone() {
    return this.phoneTarget.value.trim();
  }

  /**
   * Get message value from target
   * @returns {String} A string representing the message value
   */
  get message() {
    return this.messageTarget.value.trim();
  }

  /**
   * Get phones count target element
   * @returns {Element} HTML element representing the phones count
   */
  get phonesCount() {
    return this.phonesCountTarget;
  }

  /**
   * Get phones div target element
   * @returns {Element} HTML element representing the phones div
   */
  get phonesDiv() {
    return this.phonesDivTarget;
  }

  /**
   * Get feedback target element
   * @returns {Element} HTML element representing the feedback
   */
  get feedback() {
    return this.feedbackTarget;
  }

  /**
   * Get phone feedback target element
   * @returns {Element} HTML element representing the feedback
   */
  get phoneFeedback() {
    return this.phoneFeedbackTarget;
  }

  /**
   * Get sms button target element
   * @returns {Element} HTML element representing the feedback
   */
  get smsButton() {
    return this.smsButtonTarget;
  }

  /**
   * Get sms display target element
   * @returns {Element} HTML element representing the feedback
   */
  get smsDisplay() {
    return this.smsDisplayTarget;
  }

  /**
   * Get balance button target element
   * @returns {Element} HTML element representing the feedback
   */
  get balanceButton() {
    return this.balanceButtonTarget;
  }

  /**
   * Get senderID input target element value
   * @returns {String} A string representing the input value
   */
  get senderID() {
    return this.senderIDTarget.value.trim();
  }

  /**
   * Get apiKey input target element value
   * @returns {String} A string representing the input value
   */
  get apiKey() {
    return this.apiKeyTarget.value.trim();
  }

  /**
   * Get userName input target element value
   * @returns {String} A string representing the input value
   */
  get userName() {
    return this.userNameTarget.value.trim();
  }
}
