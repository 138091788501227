import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';
import 'select2';

export default class EnableVanillaSelectTwo extends Controller {
  connect() {
    $(this.element).select2({
      theme: 'bootstrap-5',
      placeholder: 'Type to search and select from options',
    });
  }
}
