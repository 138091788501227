/* global Set */

/**
 * Post send SMS request to the endpoint
 * @param {String} url
 * @param {FormData} data
 * @param {String} csrfToken
 * @returns {Promise<Response>} HTTP response
 */
export async function fetchPostRequest(url, data, csrfToken) {
  try {
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      body: data,
      headers: {
        'X-CSRFToken': csrfToken,
      },
    });

    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function fetchGetRequest(url, csrfToken) {
  try {
    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'X-CSRFToken': csrfToken,
      },
    });

    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Adds a value to the provided Set if the value is not already present.
 * @param {Set<String>} set The original Set to copy and potentially modify.
 * @param {String} value The value to add to the Set if it doesn't already exist.
 * @returns {Set<String>} A new Set with the value added, or the original Set if the value was already present.
 */
export function addToSet(set, value) {
  if (set.has(value)) {
    return set;
  }

  const setCopy = new Set(set);
  setCopy.add(value);
  return setCopy;
}

/**
 * Removes a value from the provided Set if the value is present.
 * @param {Set<String>} set The original Set to copy and potentially modify.
 * @param {String} value The value to remove from the Set if it exists.
 * @returns {Set<String>} A new Set with the value removed, or the original Set if the value was not present.
 */
export function removeFromSet(set, value) {
  if (!set.has(value)) {
    return set;
  }

  const setCopy = new Set(set);
  setCopy.delete(value);
  return setCopy;
}

/**
 * Removes all values from the provided Set.
 * @param {Set<String>} set The Set to clear.
 */
export function clearSet(set) {
  if (set.size === 0) {
    return set;
  }

  return new Set();
}

/**
 * Generates and displays HTML for a Set of phone numbers.
 * @param {Set<String>} phonesSet A Set of phone numbers to be displayed.
 * @returns {String} A string containing the generated HTML for each phone number.
 */
export function generatePhoneDisplay(phonesSet) {
  let htmlString = '';
  phonesSet.forEach(element => {
    htmlString += PhoneComponent(element);
  });
  return htmlString;
}

/**
 * Generates an HTML component representing a phone number.
 * @param {String} phone The phone number to display in the component.
 * @returns {String} A string containing the HTML representation of the phone number.
 */
export function PhoneComponent(phone) {
  return `
    <div class="d-flex align-items-center bg-success text-light rounded p-1 my-1 w-50">
        <strong class="me-2">${phone}</strong>
        <a type="button" class="btn-close ms-auto" data-phone=${phone} data-action="click->bulkSMS#removePhone"></a>
    </div>
    `;
}

/**
 * Get count display for given count.
 * @param {Number} count
 * @returns {String} A string containing phone count display
 */
export function getCountDisplay(count) {
  if (count === 0) return '0 phone numbers';
  if (count === 1) return '1 phone number';
  return `${count} phone numbers`;
}

/**
 * Clear input element
 * @param {Element} element
 */
export function clearInputValue(element) {
  element.value = '';
}

/**
 * Set inner HTML content for given element.
 * @param {Element} element
 * @param {String} htmlString
 */
export function setInnerHTML(element, htmlString) {
  element.innerHTML = htmlString;
}

/**
 * Set inner text content for given element.
 * @param {Element} element Html element
 * @param {String} htmlString A string in html syntax
 */
export function setTextContent(element, text) {
  element.textContent = text;
}

/**
 * Add a css class to a given element.
 * @param {Element} element Html element
 * @param {String} value A string representing name of a class
 */
export function addClass(element, value) {
  element.classList.add(value);
}

/**
 * Remove a css class from a given element.
 * @param {Element} element Html element
 * @param {String} value A string representing name of a class
 */
export function removeClass(element, value) {
  element.classList.remove(value);
}

/**
 * Check given element has a specified class.
 * @param {Element} element Html element
 * @param {String} value A string representing name of a class
 */
export function hasClass(element, value) {
  return element.classList.contains(value);
}
