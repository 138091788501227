import { Controller } from '@hotwired/stimulus';
import { removeWheelsFromNumberInputs } from '../../src/utils/generalUtils';
import { ElementIds, setupVariables } from '../constants/deliveries';
import {
  attachNewPriceInputEvent,
  autofillDimensions,
  calculateTax,
  CollectionLineItemsFormValidator,
  displayAlertMessage,
  displayErrorMessage,
  displayInputErrors,
  extractPriceFetchRequestData,
  fetchPriceData,
  handleNoPriceFetch,
  handleParcelItemDataResponse,
  handlePriceFetchResponse,
  handlePriceFetchSuccess,
  hideInputErrors,
  hideLoadingOverlay,
  postDataFunc,
  postParcelItemData,
  handleResponseError,
  refetchParcelPriceBeforeSubmit,
  showLoadingOverlay,
  showNewPriceInputHideSubtotalText,
  toggleCollectionDeliveryButton,
  updateTaxAndTotalAmount,
  validateAgainstCollectionQuantity,
  validateClassOfService,
  validateHeight,
  validateLength,
  validateNewPrice,
  validateQuantity,
  validateWeight,
  validateWidth,
} from '../scripts/deliveries/deliveriesUtils';
export default class createCollectionLine extends Controller {
  static formElementConstantsObj = {};
  static SKU_PERCENTAGE;
  static VAT_PERCENTAGE;
  static CREATE_COLLECTION_LINE_URL;
  static PERCENTAGE_BASE = 100;

  connect() {
    this.setupControllerVariables();
    removeWheelsFromNumberInputs();
    this.attachNewPriceInputEvents();
    this.attachInputValidationHandlers();
  }

  setupControllerVariables() {
    const orderType = this.element.getAttribute('data-orderType');
    const formElementConstantsObj = setupVariables(ElementIds);
    createCollectionLine.formElementConstantsObj = formElementConstantsObj;

    const defaultSKUTaxRateValue = parseFloat(
      formElementConstantsObj.defaultSKUTaxRateValue,
    );
    const SKU_PERCENTAGE = parseFloat(
      defaultSKUTaxRateValue /
        (defaultSKUTaxRateValue + createCollectionLine.PERCENTAGE_BASE),
    );
    const ZERO_RATED_VAT = formElementConstantsObj.ZERO_RATED_VAT;
    createCollectionLine.VAT_PERCENTAGE = !isNaN(defaultSKUTaxRateValue)
      ? SKU_PERCENTAGE
      : ZERO_RATED_VAT;
    createCollectionLine.SKU_PERCENTAGE = SKU_PERCENTAGE;
    const collectionIdValue = formElementConstantsObj.collectionIdValue;
    createCollectionLine.CREATE_COLLECTION_LINE_URL =
      orderType !== 'DELIVERY'
        ? `/deliveries/api/create_collection_line_item/${collectionIdValue}/`
        : `/deliveries/api/create_delivery_order_line_item_api/${collectionIdValue}/`;
  }

  async handleFetchPrice(event) {
    let fetchPriceButton = event.target;
    let formElementConstantsObj = createCollectionLine.formElementConstantsObj;
    // validate the form data
    let isFormValid = this.validateForm();
    let isCollectionLineQuantityValid =
      await this.validateCollectionQuantity(fetchPriceButton);
    if (isFormValid && isCollectionLineQuantityValid) {
      showLoadingOverlay(formElementConstantsObj);
      showNewPriceInputHideSubtotalText(formElementConstantsObj);
      toggleCollectionDeliveryButton(formElementConstantsObj);
      hideLoadingOverlay(formElementConstantsObj);
    }
  }

  validateForm() {
    // register form inputs for validation
    /**
     * Destructure all the form fields from elements that need to be validated
     * Along with the elements that display their errors messages
     * This is made possible using an IIFE
     */
    let formElementsConstantsObj = createCollectionLine.formElementConstantsObj;

    // validator functions
    let validators = {
      validateLength,
      validateWidth,
      validateWeight,
      validateHeight,
      validateQuantity,
      validateClassOfService,
    };
    // Destructure
    const formElementsObj = (constantsObj => {
      // Extracting only the needed properties and creating a new object
      const {
        lengthInput,
        lengthInputValueErrMsg,
        widthInput,
        widthInputValueErrMsg,
        heightElement,
        heightInputValueErrMsg,
        weightInput,
        weightInputValueErrMsg,
        quantity,
        quantityInputValueErrMsg,
        classOfServiceElement,
        classOfServiceInputValueErrMsg,
      } = constantsObj;

      return {
        lengthInput,
        lengthInputValueErrMsg,
        widthInput,
        widthInputValueErrMsg,
        heightElement,
        heightInputValueErrMsg,
        weightInput,
        weightInputValueErrMsg,
        quantity,
        quantityInputValueErrMsg,
        classOfServiceElement,
        classOfServiceInputValueErrMsg,
      };
    })(formElementsConstantsObj);
    // instantiate the form validator class
    const formValidator = new CollectionLineItemsFormValidator(
      validators,
      formElementsObj,
    );
    const isFormValid = formValidator.validateForm();
    return isFormValid;
  }

  attachInputValidationHandlers() {
    let formElementConstantsObj = createCollectionLine.formElementConstantsObj;
    let { parcelSizeInput } = formElementConstantsObj;
    let { lengthInput, lengthInputValueErrMsg } = formElementConstantsObj;
    let { widthInput, widthInputValueErrMsg } = formElementConstantsObj;
    let { heightElement, heightInputValueErrMsg } = formElementConstantsObj;
    let { weightInput, weightInputValueErrMsg } = formElementConstantsObj;
    let { quantity: quantityInput, quantityInputValueErrMsg } =
      formElementConstantsObj;
    let { classOfServiceElement, classOfServiceInputValueErrMsg } =
      formElementConstantsObj;
    lengthInput.addEventListener('input', () =>
      validateLength({ lengthInput, lengthInputValueErrMsg }),
    );
    widthInput.addEventListener('input', () =>
      validateWidth({ widthInput, widthInputValueErrMsg }),
    );
    weightInput.addEventListener('input', () =>
      validateWeight({ weightInput, weightInputValueErrMsg }),
    );
    heightElement.addEventListener('input', () =>
      validateHeight({ heightElement, heightInputValueErrMsg }),
    );
    quantityInput.addEventListener('input', () =>
      validateQuantity({ quantity: quantityInput, quantityInputValueErrMsg }),
    );
    classOfServiceElement.addEventListener('change', () =>
      validateClassOfService({
        classOfServiceElement,
        classOfServiceInputValueErrMsg,
      }),
    );
    let validators = {
      validateLength,
      validateWidth,
      validateHeight,
    };
    let inputsWithErrorElements = {
      lengthInput,
      lengthInputValueErrMsg,
      widthInput,
      widthInputValueErrMsg,
      heightElement,
      heightInputValueErrMsg,
    };
    parcelSizeInput.addEventListener('change', () => {
      // Get the selected option
      const selectedOption =
        parcelSizeInput.options[parcelSizeInput.selectedIndex];
      // Get the text content of the selected option
      const selectedTextContent = selectedOption.textContent;
      // Extract the text within parentheses
      const dimensionsRegex = /\((.*?)\)/; // Regular expression to extract text within parentheses
      const match = dimensionsRegex.exec(selectedTextContent);
      const selectedText = match ? match[1] : null;
      const constants = { lengthInput, widthInput, heightElement };
      // Call autofillDimensions function to update input values based on selected text
      autofillDimensions(
        selectedText,
        constants,
        validators,
        inputsWithErrorElements,
      );
    });
  }

  attachNewPriceInputEvents() {
    let formElementConstantsObj = createCollectionLine.formElementConstantsObj;
    let eventHandlers = { validateNewPrice, updateTaxAndTotalAmount };
    let taxHandlersAndArguments = {
      calculateTax,
      arguments: {
        VAT_PERCENTAGE: parseFloat(
          formElementConstantsObj.orgVATTaxRateElement.textContent,
        ),
        quantityInput: formElementConstantsObj.newPriceInput,
        PERCENTAGE_BASE: createCollectionLine.PERCENTAGE_BASE,
      },
    };
    attachNewPriceInputEvent(
      formElementConstantsObj,
      eventHandlers,
      taxHandlersAndArguments,
    );
  }

  async validateCollectionQuantity(button) {
    const orderType = button.getAttribute('data-orderType');
    if (orderType === 'DELIVERY') {
      return true;
    } else {
      const actionType = button.getAttribute('data-actionType');
      const lineItemId = button.getAttribute('data-lineItemId');
      let formElementConstantsObj =
        createCollectionLine.formElementConstantsObj;
      let collectionId = formElementConstantsObj.collectionIdValue;
      let quantityInput = formElementConstantsObj.quantity;
      let quantityValue = quantityInput.value.trim();

      let validateQuantityRequestData = {
        quantity: quantityValue,
        action_type: actionType,
        line_item_id: lineItemId,
      };
      let validateCollectionQuantityUrl = `/deliveries/api/validate_collection_quantity/${collectionId}/`;
      let validateCollectionQuantityHandlers = {
        hideInputErrors,
        displayInputErrors,
        postDataFunc,
      };
      let validateQuantityhtmlObjects = {
        errMsgElement: formElementConstantsObj.quantityInputValueErrMsg,
        csrf_token: formElementConstantsObj.csrf_token,
      };
      let isCollectionLineQuantityValid =
        await validateAgainstCollectionQuantity(
          quantityInput,
          validateQuantityRequestData,
          validateCollectionQuantityUrl,
          validateCollectionQuantityHandlers,
          validateQuantityhtmlObjects,
        );
      return isCollectionLineQuantityValid;
    }
  }

  async submitCollectionLine(event) {
    const saveCollectionLineBtn = event.target;
    const actionType = saveCollectionLineBtn.getAttribute('data-actionType');
    const lineItemId = saveCollectionLineBtn.getAttribute('data-lineItemId');
    let saveCollectionLineBtnAttr = saveCollectionLineBtn.getAttribute(
      'data-saveAndEditPrice',
    );
    const saveAndEditPrice = saveCollectionLineBtnAttr == 'true' ? true : false;

    let formElementConstantsObj = createCollectionLine.formElementConstantsObj;
    let { newPriceInput, newPriceInputValueErrMsg } = formElementConstantsObj;

    let isCollectionFormValid = this.validateForm();
    let isNewPriceInput = validateNewPrice(
      // valid/true if the input is hidden, meaning  price fetch was successful
      newPriceInput,
      newPriceInputValueErrMsg,
    );

    let isCollectionLineQuantityValid = await this.validateCollectionQuantity(
      saveCollectionLineBtn,
    );

    if (
      isCollectionFormValid &&
      isNewPriceInput &&
      isCollectionLineQuantityValid
    ) {
      let { objectData: parcelItemformDataWithPrice } =
        extractPriceFetchRequestData(formElementConstantsObj);
      let CREATE_COLLECTION_LINE_URL =
        createCollectionLine.CREATE_COLLECTION_LINE_URL;
      let savingStrategy = {
        saveAsDraft: true,
        saveAndEditPrice: saveAndEditPrice,
        action_type: actionType,
        line_item_id: lineItemId,
      };

      let taxHandlersAndArguments = {
        calculateTax,
        arguments: {
          VAT_PERCENTAGE: parseFloat(
            formElementConstantsObj.orgVATTaxRateElement.textContent,
          ),
          quantityInput: formElementConstantsObj.newPriceInput,
          PERCENTAGE_BASE: createCollectionLine.PERCENTAGE_BASE,
        },
      };

      let reponseHandlers = {
        handlePriceFetchResponse,
        handleResponseError,
        handlerDependencies: {
          handleNoPriceFetch,
          handlePriceFetchSuccess,
        },
      };

      let createParcelItemHandler = {
        postParcelItemData,
        postDataFunc,
        responseHandlers: {
          handleParcelItemDataResponse,
          handleResponseError,
          dependencies: {
            hideLoadingOverlay,
            showLoadingOverlay,
            displayAlertMessage,
            displayErrorMessage,
          },
        },
      };
      let fetchPriceFuncHandler = {
        fetchPriceData,
      };
      let dependencies = {
        showLoadingOverlay,
      };

      // post data
      refetchParcelPriceBeforeSubmit(
        dependencies,
        fetchPriceFuncHandler,
        parcelItemformDataWithPrice,
        savingStrategy,
        formElementConstantsObj,
        CREATE_COLLECTION_LINE_URL,
        taxHandlersAndArguments,
        reponseHandlers,
        createParcelItemHandler,
      );
    }
  }
}
